import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import ContentModule from "../../modules/content-render";
import "./assets/styles/_index.scss"
import {CTALink} from "../../utils/common/utils"

const { ContentModule } = require("@starberry/gatsby-theme-utils/Modules/ContentModule")
//const { CTALink } = require("@starberry/gatsby-theme-utils/Modules/CTALinkModule")

const LandingPlainSection = (props) => {
    return (
        <section className={`${props.alignCenter ? "text-center" : ""} landing-plain-section-wrapper`}>
            <Container>
                <Row className={props.layout === "center_align" ? "justify-content-center" : ""}>
                    <Col xl={8}>
                        {props.title && <h2>{props.title}</h2>}
                        <div className="inner-section">
                            <ContentModule Content={props.content.data.content} />
                        </div>
                        {props.cta_1_title && props.cta_1_link &&
                            <div className="link-text">
                                <CTALink class="" link={props.cta_1_link} title={props.cta_1_title} target_window={props.cta_1_link.target_window} />
                            </div>
                        }
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default LandingPlainSection